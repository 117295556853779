<template>
  <div class="s-layout" :style="colorObject">
    <Loading v-if="isLoading" loading-message="載入中..." :background-color="'#ffffff'"/>
    <div class="s-container">
      <div>
        <Header
          :page-title="getModuleConfig('page_title')"
          :page-image="getModuleConfig('page_image')"
          :page-kv-image="getModuleConfig('page_kv_image')"
          :header-section="getModuleConfig('header_section')"
          :meta="meta"
        />

        <!-- page: redeem start -->
        <div v-if="page === 'redeem'" class="content">
          <!-- Card Start -->
          <div class="card">
            <!-- Redeem 輸入框 Start-->
            <input
              v-if="editable"
              class="redeem__input field__input"
              placeholder="請輸入序號"
              v-model="redeemCode"
            />
            <div v-else class="redeem">
              <span class="redeem__code">{{ redeemCode }}</span>
            </div>
            <!-- Redeem 輸入框 End -->

            <div class="card__divider"></div>
            <div class="card__info">
              <!-- 兌換說明欄位 Start -->
              <template v-if="event">
                <div class="card__title">{{ redeemTitle }}</div>
                <div v-html="redeemMessage"></div>
              </template>
              <!-- 兌換說明欄位 End -->
            </div>
          </div>
          <!-- Card End -->
          <!-- Buttons Start -->
          <div class="btn">
            <button
              class="s-btn s-btn-bg-primary s-btn-xs"
              type="button"
              @click="redeem()"
              :disabled="isLoading"
            >
              {{ event.config['redeem_button_text'] }}
            </button>
          </div>
          <!-- Buttons End -->
        </div>
        <!-- page: redeem End -->

        <!-- page: form Start -->
        <div v-if="page === 'form'" class="content">
          <div class="card">
            <div class="card__info">
              <div v-if="formMessage" v-html="formMessage"></div>
              <div class="form">
                <!-- fields -->
                <div
                  v-for="(field, index) in fields.filter((x) => {
                    return x.hidden == undefined || !x.hidden;
                  })"
                  :key="`field.${index}`"
                  class="field"
                >
                  <!-- select type -->
                  <div v-if="field.type === 'select' && field.matched_key && !field.provider">
                    <label
                      class="field__label"
                      :class="field.required === true ? 'field--required' : null"
                      >{{ field.title }}</label
                    >
                    <SharedSelect
                      v-model="form[`${field.field_key}`]"
                      :options="field.options"
                      :hasBorder="false"
                      :placeholder="displayPlaceholder(field)"
                      :disabled="field.disabled"
                    />
                    <small v-if="field.memo" class="field__desc">{{
                      field.memo
                    }}</small>
                    <div
                      v-if="v$.form[field.field_key].$error"
                      class="form__error-msg"
                    >
                      輸入的資料有誤或未填寫，請確認
                    </div>
                  </div>
                  <!-- select type -->

                  <!-- radio type -->
                  <div v-else-if="field.type === 'radio' && field.matched_key">
                    <label
                      class="field__label"
                      :class="field.required === true ? 'field--required' : null"
                      >{{ field.title }}</label
                    >
                    <div
                      class="s-flex flex-wrap"
                      :class="field.is_vertical && 'flex-column'"
                    >
                      <SharedRadioButton
                        v-for="(option, index) in field.options"
                        v-model="form[`${field.field_key}`]"
                        :id="field.title + index"
                        :key="field.title + index"
                        :value="option.value"
                        :name="field.matched_key"
                        :disabled="field.disabled"
                      >
                        {{ option.text }}
                      </SharedRadioButton>
                    </div>
                    <small v-if="field.memo" class="field__desc">{{
                      field.memo
                    }}</small>
                    <div
                      v-if="v$.form[field.field_key].$error"
                      class="form__error-msg"
                    >
                      輸入的資料有誤或未填寫，請確認
                    </div>
                  </div>
                  <!-- radio type -->

                  <!-- checkbox type -->
                  <div v-else-if="field.type === 'checkbox' && field.matched_key">
                    <label
                      class="field__label"
                      :class="field.required === true ? 'field--required' : null"
                      >{{ field.title }}</label
                    >
                    <div class="s-flex">
                      <b-form-checkbox-group
                        v-model="form[`${field.field_key}`]"
                        :options="field.options"

                        :stacked="field.is_vertical"
                        :disabled="field.disabled"
                      ></b-form-checkbox-group>
                    </div>
                    <small v-if="field.memo" class="field__desc">{{
                      field.memo
                    }}</small>
                    <div
                      v-if="v$.form[field.field_key].$error"
                      class="form__error-msg"
                    >
                      輸入的資料有誤或未填寫，請確認
                    </div>
                  </div>
                  <!-- checkbox type -->

                  <!-- text type -->
                  <div v-else-if="field.matched_key">
                    <label
                      class="field__label"
                      :class="field.required === true ? 'field--required' : null"
                      >{{ field.title }}</label
                    >
                    <input
                      class="field__input"
                      v-model="form[field.field_key]"
                      :class="{ invalid: v$.form[field.field_key].$error }"
                      :placeholder="displayPlaceholder(field)"
                      :disabled="field.disabled"
                    />
                    <small v-if="field.memo" class="field__desc">{{
                      field.memo
                    }}</small>
                    <div
                      v-if="v$.form[field.field_key].$error"
                      class="form__error-msg"
                    >
                      輸入的資料有誤或未填寫，請確認
                    </div>
                  </div>
                  <!-- text type -->
                </div>
                <!-- fields -->
              </div>
            </div>
          </div>

          <div class="btn">
            <button
              class="s-btn s-btn-bg-primary s-btn-xs"
              type="button"
              @click="submitForm()"
              :disabled="isLoading"
            >
              {{ event.config['form_button_text'] }}
            </button>
          </div>
        </div>
        <!-- page: form End -->

        <!-- page: no_gift, already_joined, success -->
        <div v-if="['no_gift', 'already_joined', 'success', 'expired'].includes(page)" class="content">
          <div class="card">
            <div class="card__info">
              <div class="card__title">
                {{ statusTitle }}
              </div>
              <div v-html="statusMessage"></div>
            </div>
          </div>
        </div>
        <!-- page: no_gift, already_joined, success -->



        <div class="fixed-footer" v-html="this.event.config['warnning_message']">
        </div>

      </div>
    </div>
  </div>
</template>

<script>
// Packages
import { mapGetters } from "vuex";
import { isAfter } from "date-fns";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import _ from "lodash";
// Waltily Utils
import { themeColorFn } from "@/mixins/liff/themeColor";
import redeemApi from "@/apis/liff/v2/redeem";
import dateOptionMixin from "@/mixins/liff/dateOption";
import https from "@/apis/liff/v2/https";
// Components
import Header from "@/components/Page/Liff/Shared/HeaderV3";
import SharedSelect from "@/components/Page/Liff/Shared/Select";
import SharedRadioButton from "@/components/Page/Liff/Shared/RadioButton";
import Loading from "@/components/Page/Liff/Shared/Loading";

export default {
  mixins: [
    themeColorFn({ themeConfigPage: 'redeem' }),
    dateOptionMixin,
  ],
  components: {
    Header,
    Loading,
    SharedSelect,
    SharedRadioButton,
  },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),
  validations() {
    let form = {};
    let mobileIsValid = {};

    for (let i = 0; i < this.fields.length; i++) {
      let field = this.fields[i];
      // 一般欄位規則
      form[field.field_key] = field.required === true ? { required } : {};
    }

    return {
      form,
      mobileIsValid,
    };
  },
  mounted() {
    if (this.$route.params.redeem_code) {
      this.redeemCode = this.$route.params.redeem_code;
    } else {
      this.editable = true;
    }

    this.fetchEvent();
  },
  data() {
    return {
      editable: false,
      isLoading: false,
      eventCode: 'kirin_qrcode_202406',
      event: {
        config: {},
      },
      redeemCode: null,
      page: 'redeem', // redeem, form, no_gift, already_joined, success,
      form: {},
      fields: [],
    };
  },
  computed: {
    ...mapGetters({
      meta: "liffGeneral/meta",
      themeConfig: "liffGeneral/themeConfig",
    }),
    // 判斷 Event 是否在活動時間內
    isEventTime() {
      if (this.event) {
        const now = new Date();
        return isAfter(now, new Date(this.event.start_at)) && isAfter(new Date(this.event.end_at), now);
      }
      return false;
    },
    redeemTitle() {
      if (this.editable) {
        return this.event.config['redeem_title'] ?? '說明';
      } else {
        return this.event.config['redeem_filled_title'] ?? '說明';
      }
    },
    redeemMessage() {
      if (! this.isEventTime) {
        return this.event.config['expired_message'] ?? '活動已結束';
      } else {
        if (this.editable) {
          return this.event.config['redeem_message'] ?? '兌換說明';
        } else {
          return this.event.config['redeem_filled_message'] ?? '兌換說明';
        }
      }
    },
    statusTitle() {
      switch (this.page) {
        case "no_gift":
          return this.event.config['no_gift_title'] ?? '說明';
        case "already_joined":
          return this.event.config['already_joined_title'] ?? '說明';
        case "success":
          return this.event.config['success_title'] ?? '恭喜您！';
        case "expired":
          return this.event.config['expired_title'] ?? '很抱歉';
      }

      return '';
    },
    statusMessage() {
      switch (this.page) {
        case "no_gift":
          return this.event.config['no_gift_message'] ?? '';
        case "already_joined":
          return this.event.config['already_joined_message'] ?? '';
        case "success":
          return this.event.config['success_message'] ?? '';
        case "expired":
          return this.event.config['expired_message'] ?? '';
      }

      return '';
    },
    formMessage() {
      return this.event.config['form_message'] ?? false;
    },
  },

  methods: {
    initFieldAndForm(fields) {
      // Init fields
      if (!fields) return;

      fields = _.map(fields, (field) => {
        let field_key;
        if (field.matched_key) {
          field_key = field.matched_key.startsWith("extra.")
            ? field.matched_key.replace("extra.", "")
            : field.matched_key;
        } else {
          field_key = field.type;
        }

        return {
          ...field,
          field_key,
        }
      });

      this.fields = _.sortBy(fields, "order");

      // Init form
      var form = {};

      fields.forEach((field) => {
        if (field.type === 'checkbox') {
          form[field.field_key] = [];
        } else {
          form[field.field_key] = null;
        }
      });

      this.form = form;
    },
    getModuleConfig(key) {
      return this.$store.getters["liffModule/getConfig"]("liff_redeem", key);
    },
    isFieldShow(key) {
      return this.fields.some((field) => field.type === key);
    },
    isFieldRequired(key) {
      return this.fields.some(
        (field) => field.type === key && field.required == true
      );
    },
    displayPlaceholder(field, defaultMessage = null) {
      if (field.placeholder) return field.placeholder;
      if (defaultMessage) return defaultMessage;
      if (field.title) return "請選擇" + field.title;
      return "";
    },
    displayTitle(field, defaultMessage = null) {
      if (field.title) return field.title;
      if (defaultMessage) return defaultMessage;
      return "";
    },
    async fetchEvent() {
      try {
        this.isLoading = true;

        const response = await redeemApi.getRedeemEvent({
          event_code: this.eventCode,
          check_gift_left: true,
        });

        this.event = response.data.data.event;
        this.initFieldAndForm(this.event.config['fields']);

        const giftLeft = response.data.data.is_gift_left;

        if (!this.isEventTime) {
          this.page = 'expired';
        }

        if (giftLeft !== null && giftLeft == 0) {
          this.page = 'no_gift';
        }
      } catch (error) {
        console.error(error);
        if (error.response.status === 404) {
          this.$swal('錯誤', '活動資訊讀取失敗', 'error');
        } else {
          this.$swal('錯誤', '頁面讀取錯誤', 'error')
        }
      } finally {
        this.isLoading = false;
      }
    },
    async redeem() {
      try {
        if (!this.redeemCode) {
          this.$swal('提醒', '請輸入序號', 'warning');
          return;
        }

        this.isLoading = true;
        let redeemCode = this.redeemCode;

        let response = await https.get(`${this.$store.state.liffGeneral.orgCode}/liff/kirin/check`, {
          params: {
            redeem_code: redeemCode.toLowerCase(),
            event_code: this.eventCode,
          }
        })
        if (response.data.message === 'SUCCESS') {
          this.page = 'form';
        }
      } catch (error) {
        console.error(error);
        if (error.response.data.message) {
          switch (error.response.data.message) {
            case "EVENT_NOT_FOUND":
              this.$swal('錯誤', '活動資訊錯誤', 'error');
              break;
            case "ALREADY_JOINED":
              this.page = 'already_joined';
              break;
            case "REDEEM_CODE_NOT_FOUND":
              this.$swal(this.event.config['not_found_title'] ?? '提醒', this.event.config['not_found_message'] ?? '找不到序號', 'warning');
              break;
            case "REDEEM_CODE_ALREADY_USED":
              this.$swal('提醒', this.event.config['already_used_message'] ?? '序號已使用', 'warning');
              break;
            case "GIFT_NO_LEFT":
              this.page = 'no_gift';
              break;
          }
          return;
        }
        this.$swal('錯誤', '', 'error');
      } finally {
        this.isLoading = false;
      }
    },
    async submitForm() {
      try {
        const validateResult = await this.v$.$validate();
        if (! validateResult) return;

        this.isLoading = true;

        let response = await https.post(`${this.$store.state.liffGeneral.orgCode}/liff/kirin/submit`, {
          redeem_code: this.redeemCode.toLowerCase(),
          event_code: this.eventCode,
          extra: this.form,
        });

        if (response.data.status === 'success') {
          this.page = 'success';
        } else {
          throw Error('submit response unknown');
        }
      } catch (error) {
        console.error(error);
        if (error.response.data.message) {
          switch (error.response.data.message) {
            case "EVENT_NOT_FOUND":
              this.$swal('錯誤', '活動資訊錯誤', 'error');
              break;
            case "ALREADY_JOINED":
              this.page = 'already_joined';
              break;
            case "REDEEM_CODE_NOT_FOUND":
              this.$swal('提醒', this.event.config['not_found_message'] ?? '找不到序號', 'warning');
              break;
            case "REDEEM_CODE_ALREADY_USED":
              this.$swal('提醒', this.event.config['already_used_message'] ?? '序號已使用', 'warning');
              break;
            case "GIFT_NO_LEFT":
              this.page = 'no_gift';
              break;
            default:
              this.$swal('錯誤', '兌換發生錯誤，請稍後再試', 'error');
              break;
          }
          return;
        }
        this.$swal('錯誤', '', 'error');
      } finally {
        this.isLoading = false;
      }
    },
  }
}
</script>

<style lang="scss">
@import "../../../assets/css/liff/main.css";
@import "../../../assets/css/liff/liff_init_theme_config.css";
@import '../../../assets/scss/shared/components/_fields.scss';

.s-layout {
  color: var(--liff-primary_text_color);
  background-color: var(--liff-layout_bg_color);
  --s-gray: #979797 !important;
  --s-gray-light: #f0f0f0 !important;
}

.s-container {
  min-height: 100vh;
  position: relative;
  max-width: 768px;
  margin: auto;
}

.form {
  padding: 0 5px;
  &__error-msg {
    color: #fe0000;
    font-size: 12px;
    margin-top: 8px;
  }
}

.s-layout,
.s-layout *,
.s-layout *:before,
.s-layout *:after {
  --s-secondary: #de006f;
  --s-warning: #ed6c00;
  --s-danger: #fe0000;
  --s-gray-darker: #363636;
  --s-gray-dark: #2c2c2e;
  --s-gray: #979797 !important;
  --s-gray-light: #c0c4cc;
  --s-gray-lighter: #f2f2f7;
}
.content {
  height: calc(100vh - 56px);
  overflow-y: auto;
  margin: 0 24px;
  padding-bottom: 24px;
}

.logo {
  margin: auto;
  margin-top: 12px;
  margin-bottom: 12px;
  max-width: 300px;
  max-height: 100px;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}


.card {
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid #e5e5ea;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 24px;
  margin-top: 12px;

  &__title {
    color: #2c2c2e;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 8px;
  }

  &__divider {
    border-bottom: 2px dashed #e5e5ea;
  }
  &__info {
    color: #636366;
    line-height: 24px;
    font-size: 16px;
    padding-top: 12px;
    b {
      color: #2c2c2e;
      font-weight: 500;
    }
  }
}


.redeem {
  background: url("./images/bg_redeem.png");
  background-size: cover;
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
  &__code {
    color: #2c2c2e;
    font-size: 19px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
  }

  &__input {
    background: #ffffff;
    border: 1px solid #636366;
    border-radius: 10px !important;
    border: 1px solid #636366;
    padding: 15px 12px;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 32px;

    &::placeholder {
      letter-spacing: -0.408px;
      color: #b7b7b7;
    }
  }
}

.btn {
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;

  button {
    background: var(--liff-button-color);
    color: var(--liff-button-text-color);
  }
}

.gift__image {
  width: 100%;
  object-fit: contain;
}
.loading-spinner {
  order: 4;
}


.custom-checkbox {
  padding-left: 1.5rem;
  margin-right: 1rem;

  .custom-control-input {
    & + label {
      position: relative;
      cursor: pointer;
      padding: 0;
      color: var(--liff-primary_text_color);
      font-size: 16px;
    }

    & + label:before {
      content: "";
      margin-right: 8px;
      display: inline-block;
      vertical-align: text-top;
      width: 18px;
      height: 18px;
      border: 1px solid var(--liff-button-color) !important;
      border-radius: 4px;
      background: white;
      left: -1.5rem;
    }

    & + label::after {
      left: -1.5rem;
    }

    &:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    &:checked + label:before,
    &:active + label:before {
      background: var(--liff-button-color) !important;
    }

    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    &:disabled + label:before {
      box-shadow: none;
      background: var(--s-gray-light);
    }
  }
}

.fixed-footer{
  position:sticky;
  bottom: 0;
  z-index: 1;
}

.field__label {
  font-size: 16px !important;
}

.field__desc {
  font-size: 14px !important;
}

</style>
